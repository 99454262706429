import React, { useEffect, useRef } from "react";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { ResetTransactionInfo } from "../../Redux/actions/PaymentActions";

const FailSVG = () => (
  <svg fill="red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
    <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-33.8-217.9c7.8-7.8 7.8-20.5 0-28.3L196.3 192l17.9-17.9c7.8-7.8 7.8-20.5 0-28.3-7.8-7.8-20.5-7.8-28.3 0L168 163.7l-17.8-17.8c-7.8-7.8-20.5-7.8-28.3 0-7.8 7.8-7.8 20.5 0 28.3l17.9 17.9-17.9 17.9c-7.8 7.8-7.8 20.5 0 28.3 7.8 7.8 20.5 7.8 28.3 0l17.8-17.8 17.8 17.8c7.9 7.7 20.5 7.7 28.4-.2zm160-92.2c-7.8-7.8-20.5-7.8-28.3 0L328 163.7l-17.8-17.8c-7.8-7.8-20.5-7.8-28.3 0-7.8 7.8-7.8 20.5 0 28.3l17.9 17.9-17.9 17.9c-7.8 7.8-7.8 20.5 0 28.3 7.8 7.8 20.5 7.8 28.3 0l17.8-17.8 17.8 17.8c7.8 7.8 20.5 7.8 28.3 0 7.8-7.8 7.8-20.5 0-28.3l-17.8-18 17.9-17.9c7.7-7.8 7.7-20.4 0-28.2zM248 272c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64z" />
  </svg>
);

function Fail({ text }) {
  const dispatch = useDispatch();
  const PaymentState = useSelector((state) => state.payment);
  const FormRef = useRef();
  useEffect(() => {
    //Delete Signature and orderID
    dispatch(ResetTransactionInfo());
  }, []);

  if (PaymentState.Redirect) {
    FormRef.current.submit();
  }

  return (
    <React.Fragment>
      {PaymentState.loading && <Loading text="Request is being processed" />}
      <Loading fixed={false} Svg={FailSVG} text={text} />
      <form
        ref={FormRef}
        method="post"
        action={`${process.env.GATSBY_CASHFREE_POST}`}
      >
        <input
          type="hidden"
          name="appId"
          value={PaymentState.PaymentData.appId}
        />
        <input
          type="hidden"
          name="orderId"
          value={PaymentState.PaymentData.orderId}
        />
        <input
          type="hidden"
          name="orderAmount"
          value={PaymentState.PaymentData.orderAmount}
        />
        <input
          type="hidden"
          name="orderCurrency"
          value={PaymentState.PaymentData.orderCurrency}
        />
        <input
          type="hidden"
          name="customerName"
          value={PaymentState.PaymentData.customerName}
        />
        <input
          type="hidden"
          name="customerEmail"
          value={PaymentState.PaymentData.customerEmail}
        />
        <input
          type="hidden"
          name="customerPhone"
          value={PaymentState.PaymentData.customerPhone}
        />
        <input
          type="hidden"
          name="returnUrl"
          value={PaymentState.PaymentData.returnUrl}
        />
        <input
          type="hidden"
          name="signature"
          value={PaymentState.PaymentData.signature}
        />
      </form>
    </React.Fragment>
  );
}

export default Fail;
