import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { PaymentSuccess } from "../../Redux/actions/PaymentActions";
import { EmptyCart } from "../../Redux/actions/CartActions";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PAYMENT_DONE } from "../../events";

const SuccessSVG = () => {
  return (
    <svg
      fill="#00ff3d"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
    </svg>
  );
};
const PendingSvg = () => (
  <svg fill="#d19851" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" />
  </svg>
);
const FailSVG = () => (
  <svg fill="red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
    <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-33.8-217.9c7.8-7.8 7.8-20.5 0-28.3L196.3 192l17.9-17.9c7.8-7.8 7.8-20.5 0-28.3-7.8-7.8-20.5-7.8-28.3 0L168 163.7l-17.8-17.8c-7.8-7.8-20.5-7.8-28.3 0-7.8 7.8-7.8 20.5 0 28.3l17.9 17.9-17.9 17.9c-7.8 7.8-7.8 20.5 0 28.3 7.8 7.8 20.5 7.8 28.3 0l17.8-17.8 17.8 17.8c7.9 7.7 20.5 7.7 28.4-.2zm160-92.2c-7.8-7.8-20.5-7.8-28.3 0L328 163.7l-17.8-17.8c-7.8-7.8-20.5-7.8-28.3 0-7.8 7.8-7.8 20.5 0 28.3l17.9 17.9-17.9 17.9c-7.8 7.8-7.8 20.5 0 28.3 7.8 7.8 20.5 7.8 28.3 0l17.8-17.8 17.8 17.8c7.8 7.8 20.5 7.8 28.3 0 7.8-7.8 7.8-20.5 0-28.3l-17.8-18 17.9-17.9c7.7-7.8 7.7-20.4 0-28.2zM248 272c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64z" />
  </svg>
);
const formatOrderSummary = (Cart, PaymentData, categories, status) => {
  let orderDetails = `<h1 style="text-align:center;">PAYMENT STATUS: ${status}</h1>`;
  orderDetails += `<hr>`;
  //Check if products in cart exists
  if (Cart.CartProducts.length > 0) {
    orderDetails +=
      '<h2 style="text-align:center; text-decoration:underline;"> Cart Products</h2>';
    orderDetails += "<ul>";
    Cart.CartProducts.forEach((product) => {
      orderDetails += `<li> <b style="text-decoration: underline;">${product.name}:</b> <b>Quantity:<span style="color:red">${product.quantity}${product.unit}</span>, Price:<span style="color:red;">₹${product.price}</span></b></li>`;
    });
    orderDetails += "</ul>";
    orderDetails += `<p style="font-size:16px;font-weight:bold;text-decoration:underline;">Cart Total:<b style="color:red;">₹${Cart.CartTotal}</b> </p> </br>`;
    orderDetails += `<hr>`;
  }
  //Check if custom menu exists
  if (Object.keys(Cart.MenuProducts).length > 0) {
    orderDetails += `<h2 style="text-align:center; text-decoration:underline;" >Custom Menu</h2/>`;
    categories.forEach((category) => {
      orderDetails += `<h3 style="text-align:center; text-decoration:underline; text-transform:uppercase;">${category}</h3>`;
      orderDetails += `<ul>`;
      Cart.MenuProducts[category]?.forEach((item) => {
        orderDetails += `<li><b >${item.name}</b></li>`;
      });
      orderDetails += `</ul>`;
    });
    orderDetails += `<p style="font-size:16px;font-weight: bold; text-decoration:underline;">Total Guests:<span style="color:red;">${Cart.MenuProducts.guests}</span></p>`;
    orderDetails += `<p style="font-size:16px;font-weight: bold; text-decoration:underline;">Custom Menu Total:<span style="color:red;">₹${Cart.MenuTotal}</span></p>`;
    orderDetails += `<hr>`;
  }
  orderDetails += `<p style="text-align: center;font-weight: bold;font-size:16px;text-decoration:underline;">Overall TOTAL:<b style="color:red;">₹${
    Cart.MenuTotal + Cart.CartTotal
  }</b> </p>`;
  orderDetails += `<h2 style="text-align:center;text-decoration:underline;">Delivery Information</h2>`;
  orderDetails += `<h4>Customer Name: ${PaymentData.customerName}</h4>`;
  orderDetails += `<h4>Customer Phone: ${PaymentData.customerPhone}</h4>`;
  orderDetails += `<h4>Customer Email: ${PaymentData.customerEmail}</h4>`;
  orderDetails += `<h4>Address Line1: ${PaymentData.address1}</h4>`;
  orderDetails += `<h4>Address Line2: ${PaymentData.address2}</h4>`;
  orderDetails += `<h4>Address Line3: ${PaymentData.address3}</h4>`;
  orderDetails += `<h4>Landmark: ${PaymentData.landMark}</h4>`;
  orderDetails += `<h4>Date: ${PaymentData.date}</h4>`;
  orderDetails += `<h4>time: ${PaymentData.time}</h4>`;
  orderDetails += '<div style="text-align:center;">&#9996;<div>';
  return orderDetails;
};
function Success({ orderId, text, status, success = true }) {
  const dispatch = useDispatch();
  const data = useStaticQuery(graphql`
    query {
      allStrapiMealCourses {
        edges {
          node {
            name
          }
        }
      }
      allStrapiEmployeeDetails {
        edges {
          node {
            PhoneNumber
            name
          }
        }
      }
    }
  `);

  const Cart = useSelector((state) => state.cart);
  const PaymentState = useSelector((state) => state.payment);
  const categories = data.allStrapiMealCourses.edges.map(
    (edge) => edge.node.name
  );
  const [APIstate, setAPIstate] = useState({
    loading: false,
    error: null,
    success: null,
  });
  useEffect(() => {
    setAPIstate({
      loading: true,
      error: null,
      success: null,
    });
    //Send an email with the order details
    //If we need to send SMS to employees
    axios
      .all([
        axios.post("/api/mailjet", {
          orderId: orderId,
          orderSummary: formatOrderSummary(
            Cart,
            PaymentState.PaymentData,
            categories,
            status
          ),
        }),
        axios.post("/api/sendSMS", {
          customerName: PaymentState.PaymentData.customerName,
          customerPhone: PaymentState.PaymentData.customerPhone,
          date: PaymentState.PaymentData.date,
          time: PaymentState.PaymentData.time,
          employeeNumbers: data.allStrapiEmployeeDetails.edges.map(
            (edge) => edge.node.PhoneNumber
          ),
          orderId: PaymentState.PaymentData.orderId,
          orderAmount: `Rs.${PaymentState.PaymentData.orderAmount}`,
        }),
      ])
      .then(
        axios.spread((data1, data2) => {
          setAPIstate({
            loading: false,
            error: null,
            success: true,
          });
          dispatch(EmptyCart());
          dispatch(PaymentSuccess());
          // setDisplay(
          //   getLoader("SUCCESS", txData, () => navigate("/"), redirectTime)
          // );
        })
      )
      .catch((err) => {
        setAPIstate({
          loading: false,
          error: true,
          success: null,
        });
        // console.dir(err);
        // setTxStatus({ loading: false, error: err.message, data: null });
      });
  }, []);

  useEffect(() => {
    window.analytics.logEvent(PAYMENT_DONE, {
      customerName: PaymentState.PaymentData.customerName,
      customerPhone: `${PaymentState.PaymentData.customerPhone}`,
      orderId: `${PaymentState.PaymentData.orderId}`,
      value: PaymentState.PaymentData.orderAmount,
    });
  }, []);
  return (
    <React.Fragment>
      {APIstate.loading ? (
        <Loading fixed={false} text="Cleaning up.." />
      ) : APIstate.error ? (
        <Loading
          fixed={false}
          Svg={FailSVG}
          text={
            <p>
              There seems to be an unexpected error. Your transaction is
              successful but we are unable to contact our staff. <br />
              Please
              <button style={{ margin: "1rem" }} className="primary-btn">
                <Link style={{ color: "white" }} to="/contact">
                  Contact us
                </Link>
              </button>{" "}
              to ensure no delay in service.
            </p>
          }
        />
      ) : (
        <Loading
          fixed={false}
          Svg={success === true ? SuccessSVG : PendingSvg}
          text={text}
        />
      )}
    </React.Fragment>
  );
}

export default Success;
