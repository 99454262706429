import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Loading";
import Success from "../components/paymentStatus/Success";
import Fail from "../components/paymentStatus/Fail";
import CryptoJS from "crypto-js";
import Logo from "../components/SVG/Logo";
import { Link } from "gatsby";
import { RestartPayment } from "../Redux/actions/PaymentActions";

//create a code using the orderId and the random key to check if the request is from our server
const getCode = (orderId) => {
  return CryptoJS.HmacSHA256(process.env.GATSBY_RANDOM_KEY, orderId).toString(
    CryptoJS.enc.Base64
  );
};

const DisplayStatus = (
  orderId,
  verified,
  status,
  setDisplay,
  PaymentState,
  dispatch
) => {
  if (!verified) {
    setDisplay(
      <Loading
        fixed={false}
        Svg={Logo}
        text={
          <p>
            Got what you need? Lets
            <button style={{ margin: "1rem" }} className="primary-btn">
              <Link style={{ color: "white" }} to="/checkout">
                Checkout
              </Link>
            </button>{" "}
            <br />
            or <br />
            <button style={{ margin: "1rem" }} className="primary-btn">
              <Link style={{ color: "white" }} to="/products">
                Browse Products
              </Link>
            </button>{" "}
            <button style={{ margin: "1rem" }} className="primary-btn">
              <Link style={{ color: "white" }} to="/orderbuild">
                Make your own mennu
              </Link>
            </button>{" "}
          </p>
        }
      />
    );
  } else {
    switch (status) {
      case "SUCCESS":
        setDisplay(
          <Success
            text={
              <p>
                Transaction Successful
                <br /> You will receive a call from our staff shortly
                <br />
                <button style={{ margin: "1rem" }} className="primary-btn">
                  <Link style={{ color: "white" }} to="/">
                    Go home
                  </Link>
                </button>
              </p>
            }
            status="SUCCESS"
            orderId={orderId}
          />
        );
        break;
      case "FLAGGED":
        setDisplay(
          <Success
            text={
              <p>
                Transaction is Pending.
                <br /> You will receive a call from our staff shortly to sort
                out the payment.
                <br />
              </p>
            }
            status="FLAGGED"
            orderId={orderId}
            success={false}
          />
        );
        break;
      case "PENDING":
        setDisplay(
          <Success
            text={
              <p>
                Transaction is Pending.
                <br /> You will receive a call from our staff shortly to sort
                out the payment.
                <br />
              </p>
            }
            success={false}
            status="PENDING"
            orderId={orderId}
          />
        );
        break;
      case "FAILED":
        setDisplay(
          <Fail
            text={
              <p>
                Transaction Failed
                <br />
                <button
                  onClick={() =>
                    dispatch(RestartPayment(PaymentState.PaymentData))
                  }
                  style={{ margin: "1rem" }}
                  className="primary-btn"
                >
                  <span style={{ color: "white" }}>Restart Payment</span>
                </button>{" "}
                <br />
                or
                <br />
                <button style={{ margin: "1rem" }} className="primary-btn">
                  <Link style={{ color: "white" }} to="/contact">
                    Contact us
                  </Link>
                </button>{" "}
                If you have been charged or are unable to make the payment.
              </p>
            }
            PaymentState={PaymentState}
          />
        );
        break;
      case "CANCELLED":
        setDisplay(
          <Fail
            text={
              <p>
                Transaction cancelled.
                <br />
                <button
                  onClick={() =>
                    dispatch(RestartPayment(PaymentState.PaymentData))
                  }
                  style={{ margin: "1rem" }}
                  className="primary-btn"
                >
                  <span style={{ color: "white" }}>Restart Payment</span>
                </button>{" "}
                <button className="primary-btn" style={{ margin: "1rem" }}>
                  <Link style={{ color: "white" }} to="/checkout">
                    Go to checkout
                  </Link>
                </button>
                <button style={{ margin: "1rem" }} className="primary-btn">
                  <Link style={{ color: "white" }} to="/">
                    Go home
                  </Link>
                </button>
              </p>
            }
          />
        );
        break;
      default:
        setDisplay(
          <Fail
            text={
              <p>
                No valid transaction detected.
                <br />
                <button
                  onClick={() =>
                    dispatch(RestartPayment(PaymentState.PaymentData))
                  }
                  style={{ margin: "1rem" }}
                  className="primary-btn"
                >
                  <span style={{ color: "white" }}>Restart Payment</span>
                </button>{" "}
                <br />
                or
                <br />
                <button style={{ margin: "1rem" }} className="primary-btn">
                  <Link style={{ color: "white" }} to="/contact">
                    Contact us
                  </Link>
                </button>{" "}
                if you have been charged, and we will resolve any issues.
              </p>
            }
          />
        );
        break;
    }
  }
};

function Paydone() {
  const dispatch = useDispatch();

  const [display, setDisplay] = useState(
    <Loading fixed={false} text="Waiting for verification" />
  );
  const PaymentState = useSelector((state) => state.payment);
  var orderId = PaymentState?.PaymentData?.orderId;
  useEffect(() => {
    var pathName = window.location.pathname.split("/");
    var status = pathName[2] || undefined;
    var verificationCode =
      pathName.slice(3, pathName.length).join("/") || undefined;
    var code = orderId ? getCode(orderId) : undefined;
    DisplayStatus(
      orderId,
      status !== undefined &&
        verificationCode !== undefined &&
        code === verificationCode,
      status,
      setDisplay,
      PaymentState,
      dispatch
    );
  }, []);
  return (
    <Layout
      title="Payment Completed Page"
      description="Thank you for shopping with N.K. cateres! We hope you had a great experience"
    >
      {display}
    </Layout>
  );
}

export default Paydone;
